<template>
    <div class="about">
        <van-nav-bar
                class="icon-back"
                title="关于软件"
                left-text="返回"
                left-arrow
                @click-left="tolast"
        />
        <van-image
                style="position: absolute;top: 20%;left: 38%"
                fill
                width="7rem"
                height="7rem"
                :src="require('../img/repair.png')"
        />
        <div style="position: absolute;top: 30%;left: 41%"><h2>办微修</h2></div>
        <div style="position: absolute;top: 35%;left: 38%"><h4>Version 10.0.0</h4></div>
        <div role="button" tabindex="0" class="van-cell van-cell--clickable" style="position: absolute;top: 60%;font-size: 18px;font-family: '微軟正黑體 Light'">
            <div class="van-cell__title"><span>喜欢本软件，分享给朋友</span></div>
            <van-cell style="position: absolute;top: 80%;left: 10%" title="显示分享面板" @click="showShare = true"/>
            <van-share-sheet
                    v-model="showShare"
                    title="立即分享给好友"
                    :options="options"
            />
            <i class="van-icon van-icon-arrow van-cell__right-icon"><!----></i>
        </div>
        <van-divider />
    </div>

</template>

<script>
    export default {
        name: "About",
        data() {
            return {
                showShare: false,
                options: [
                    [
                        {name: '微信', icon: 'wechat'},
                        {name: '朋友圈', icon: 'wechat-moments'},
                        {name: '微博', icon: 'weibo'},
                        {name: 'QQ', icon: 'qq'},
                    ],
                    [
                        {name: '复制链接', icon: 'link'},
                        {name: '分享海报', icon: 'poster'},
                        {name: '二维码', icon: 'qrcode'},
                        {name: '小程序码', icon: 'weapp-qrcode'},
                    ],
                ],
            };
        },
        methods: {
            tolast() {
                this.$router.go(-1);
            },
        }
    }
</script>